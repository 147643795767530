import classes from './styles.module.scss';
import { useGameContext } from "../../../../utils/GameContext";
import Word from './Word/Word';
import CurrentWord from './CurrentWord/CurrentWord';
import Rules from './Rules/Rules';
import { useState } from 'react';


export default function WordsList() {
    const { guesses } = useGameContext();
    const [showRules, setShowRules] = useState(true);

    return (
        <div className={classes.wordsListContainer}>
            <CurrentWord />
            {guesses.length ?
                (
                    <div className={classes.scrollContainer}>
                        <div className={classes.container}>
                            {guesses.map(guess =>
                                <Word guess={guess} />
                            )}
                        </div>
                    </div>
                )
                : (showRules && <Rules onExit={() => setShowRules(false)} />)
            }
        </div>
    )
}
