import React, { useState, useContext, createContext, useEffect } from 'react';
import { GameStatus } from '../types/GameStatus';
import { Guess } from '../types/Guess';
import { getGameHash } from './api/getGameHash';
import { makeGuess } from './api/makeGuess';
import { getWordColor } from './getWordColor';
import { getWordPercentage } from './getWordPercentage';


interface IGameContext {
    guesses: Guess[];
    gameHash: string;
    highlighted: Guess | null;
    lastGuess: Guess | null;
    status: GameStatus;
    addGuess: (word: string, onSuccess?: (guess: Guess) => void, onError?: (error: string) => void) => void;
    setHighlighted: React.Dispatch<React.SetStateAction<Guess | null>>;
    setStatus: (status: GameStatus) => void;
}

export const GameContext = createContext({} as IGameContext);

export function ProvideGameContext({ children }: { children: React.ReactNode }) {
    const context = useProvidedGameContext();

    return (
        <GameContext.Provider value={context}>
            {children}
        </GameContext.Provider>
    );
}

export function useGameContext() {
    const context = useContext(GameContext);

    return context;
}

function useProvidedGameContext() {
    const [guesses, setGuesses] = useState<Guess[]>([]);
    const [gameHash, setGameHash] = useState<string>('');
    const [lastGuess, setLastGuess] = useState<Guess | null>(null);
    const [highlighted, setHighlighted] = useState<Guess | null>(null);
    const [status, setStatus] = useState<GameStatus>('init');


    useEffect(() => {
        getGameHash({
            onSuccess: hash => {
                setGameHash(hash);
                if (hash === localStorage.getItem('gameHash')) {
                    const savedGuesses = localStorage.getItem('guesses');
                    if (savedGuesses) {
                        const parsed = JSON.parse(savedGuesses) as Guess[];
                        setGuesses(parsed);
                        if (parsed.length) {
                            setLastGuess(parsed[0]);
                        }
                        if (parsed.find(v => v.position === 0)) {
                            setStatus('end');
                        } else {
                            setStatus('playing');
                        }
                    }
                } else {
                    localStorage.setItem('gameHash', hash);
                    localStorage.removeItem('guesses');
                }
            }
        });
    }, []);


    const addGuess: IGameContext['addGuess'] = (word, onSuccess, onError) => {
        if (gameHash && !guesses.find(g => g.word === word)) {
            makeGuess({
                gameHash,
                word,
                onSuccess: guess => {
                    const newGuess = {
                        ...guess,
                        color: getWordColor(guess),
                        percentage: getWordPercentage(guess)
                    };
                    const newGuesses = [
                        ...guesses,
                        newGuess
                    ].sort((a, b) => b.percentage - a.percentage);

                    setGuesses(newGuesses);
                    setLastGuess(newGuess);
                    localStorage.setItem('guesses', JSON.stringify(newGuesses));
                    onSuccess?.(guess);
                    if (guess.position === 0) {
                        setStatus('end');
                        (window as any).ym(95999298, 'reachGoal', 'game_end');
                    }
                },
                onError: () => onError?.('Я не знаю такого слова :(')
            });
        } else {
            onError?.('Ты уже вводил это слово');
        }
    };

    const context: IGameContext = {
        guesses,
        gameHash,
        highlighted,
        lastGuess,
        status,
        addGuess,
        setHighlighted,
        setStatus
    };

    return context;
}
