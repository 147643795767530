export const colors = [
    'rgba(242, 252, 124, 1)',
    'rgba(245, 232, 119, 1)',
    'rgba(227, 194, 76, 1)',
    'rgba(220, 146, 60, 1)',
    'rgba(220, 110, 60, 1)',
    'rgba(185, 96, 116, 1)',
    'rgba(160, 93, 130, 1)',
    'rgba(50, 107, 137, 1)',
    'rgba(50, 81, 140, 1)'
];

export const wordColors = [
    'rgba(250, 242, 55, 1)',
    'rgba(243, 150, 64, 1)',
    'rgba(194, 129, 194, 1)',
    'rgba(127, 178, 199, 1)'
];
