/**
 * @param d distance
 * @param angle angle in radians
 * 
 * @returns [x, y] Cartesian coordinates
 */
export function polarToCartesian(d: number, angle: number) {
    return [
        d * Math.cos(angle),
        d * Math.sin(angle)
    ];
}