import { Guess } from "../../types/Guess";
import { API_URL, headers } from "./api";

type Data = {
    word: string;
    gameHash: string;
    onSuccess?: (data: Guess) => void;
    onError?: () => void;
};

export const makeGuess = async ({ word, gameHash, onSuccess, onError }: Data) => {
    const response = await fetch(`${API_URL}/guess/`, {
        method: 'POST',
        body: JSON.stringify({
            word,
            game_word_hash: gameHash
        }),
        headers
    });

    if (response.ok) {
        const guess = await response.json();
        onSuccess?.({ ...guess, word });
    } else {
        onError?.();
    }
};
