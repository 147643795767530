import classes from './styles.module.scss';
import { Guess } from '../../../../../types/Guess';
import { useGameContext } from "../../../../../utils/GameContext";


type WordProps = {
    guess: Guess;
};

export default function Word({ guess }: WordProps) {
    const { highlighted, setHighlighted } = useGameContext();

    return (
        <div
            className={classes.wordContainer}
            style={guess === highlighted ? { borderColor: guess.color } : undefined}
            onMouseOver={() => setHighlighted(guess)}
            onMouseOut={() => setHighlighted(null)}
            data-highlighted={guess === highlighted}
        >
            <span className={classes.wordBack}>{guess.word}</span>
            <div
                className={classes.wordFill}
                style={{
                    backgroundColor: guess.color,
                    minWidth: '10px',
                    maxWidth: `${guess.percentage}%`
                }}
            >
                <span className={classes.wordFront}>{guess.word}</span>
            </div>
            <div
                className={classes.wordPercentage}
                style={{ color: guess.percentage >= 92 ? 'var(--fg-dark)' : undefined }}
            >
                {guess.percentage}%
            </div>
        </div >
    )
}
