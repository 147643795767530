import classes from './styles.module.scss';
import Content from './components/Content/Content';
import { ProvideGameContext } from './utils/GameContext';


export default function App() {
    return (
        <ProvideGameContext>
            <div className={classes.root}>
                <Content />
            </div>
        </ProvideGameContext>
    );
}
