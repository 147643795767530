import classes from './styles.module.scss';
import { ReactComponent as BackBtn } from '../../../../../assets/back.svg';
import { ReactComponent as Stars } from '../../../../../assets/stars.svg';
import paperclip from '../../../../../assets/paperclip.png';
import tape10 from '../../../../../assets/tape10.png';
import tape2 from '../../../../../assets/tape2.png';
import tape18 from '../../../../../assets/tape18.png';


type RulesProps = {
    onExit: () => void;
};

export default function Rules({ onExit }: RulesProps) {
    const rules = [
        {
            text: 'Загадано слово дня, есть неограниченное число попыток, чтобы его угадать.',
            color: 'rgba(194, 129, 194, 1)',
            extra: (
                <>
                    <img alt='tape' src={tape10} className={classes.tape10} />
                    <Stars className={classes.stars} />
                </>
            )
        },
        {
            text: 'После ввода слово отобразится на мишени и в списке — чем ближе к центру и выше в списке слов, тем ты ближе к загаданному слову.',
            color: 'rgba(227, 194, 76, 1)',
            extra: <img alt='paperclip' src={paperclip} className={classes.paperclip} />
        },
        {
            text: 'Чем меньше попыток потребовалось, тем лучше.',
            color: 'rgba(220, 110, 60, 1)',
            extra: (
                <>
                    <Stars className={classes.stars1} />
                    <img alt='tape' src={tape2} className={classes.tape2} />
                </>
            )
        },
        {
            text: 'Скорей вводи первое слово и покажи, на что способен!',
            color: 'rgba(127, 178, 199, 1)',
            extra: <img alt='tape' src={tape18} className={classes.tape18} />
        }
    ];

    return (
        <>
            <div className={classes.shadow} />

            <div className={classes.container}>
                <h3 className={classes.subheading}>
                    Правила игры
                </h3>
                <BackBtn onClick={onExit} className={classes.backBtn} />

                <div className={classes.rulesList}>
                    {rules.map((rule, i) => (
                        <div
                            className={classes.ruleContainer}
                            style={{
                                backgroundColor: rule.color,
                                marginLeft: i % 2 ? 'auto' : 0
                            }}
                        >
                            {rule.text}
                            {rule.extra ?? ''}
                        </div>
                    ))}
                </div>
            </div>

            <div className={classes.filter} />
        </>
    );
}
