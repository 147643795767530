import { useGameContext } from '../../../../../utils/GameContext';
import Word from '../Word/Word';
import classes from './styles.module.scss';


export default function CurrentWord() {
    const { lastGuess } = useGameContext();

    return lastGuess !== null ? (
        <div className={classes.lastWordContainer}>
            <div className={classes.wordContainer}>
                <div className={classes.wordFill}>
                    {lastGuess.word}
                </div>
                <div className={classes.wordPercentage}>
                    {lastGuess.percentage}%
                </div>
            </div>
        </div>
    ) : null;
}
