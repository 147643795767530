import { useRef, useState } from 'react';
import { useGameContext } from '../../../utils/GameContext';
import InputField from '../InputField/InputField';
import Map from './Map/Map';
import classes from './styles.module.scss';
import WordsList from './WordsList/WordsList';
import Toast from '../../Toast/Toast';


export default function Results() {
    const {
        status,
        addGuess
    } = useGameContext();

    const inputRef = useRef<HTMLInputElement>(null);
    const [error, _setError] = useState<string | null>(null);

    const setError = (value: typeof error) => {
        _setError(value);
        setTimeout(() => _setError(null), 2500);
    };

    const handleAddWord = (value: string) => {
        if (value.length) {
            addGuess(
                value.toLowerCase().replaceAll('ё', 'е').trim(),
                guess => {
                    setError(null);
                    if (guess.position === 0) {
                        inputRef.current?.focus();
                    }
                },
                setError
            );
        }
    };

    return (
        <div
            className={classes.root}
        >
            <Map />
            <WordsList />
            <div className={classes.inputContainer}>
                <InputField
                    forwardRef={inputRef}
                    placeholder='Введите слово'
                    isDisabled={status === 'end'}
                    onSubmit={handleAddWord}
                />
            </div>

             {error && <Toast message={error} />}
        </div>
    );
}
