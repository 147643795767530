import classes from './styles.module.scss';
import Results from './Results/Results';
import { useGameContext } from '../../utils/GameContext';
import Header from '../Header/Header';
import FinishPopup from '../FinishPopup/FinishPopup';


export default function Content() {
    const { status } = useGameContext();

    return (
        <div className={classes.container}>
            <Header />
            <div
                className={classes.content}
            >
                <Results />
            </div>
            {status === 'end' && <FinishPopup />}
        </div>
    );
}
