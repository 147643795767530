import { useEffect, useRef } from 'react';
import { useGameContext } from '../../../../utils/GameContext';
import classes from './styles.module.scss';
import * as d3 from 'd3';
import { Guess } from '../../../../types/Guess';
import { polarToCartesian } from '../../../../utils/math/polarToCartesian';
import { degToRad } from '../../../../utils/math/degToRad';
import { colors } from '../../../../utils/colors';
import { BrowserText } from '../../../../utils/BrowserText';
import { ReactComponent as PlusBtn } from '../../../../assets/plus.svg';
import { ReactComponent as MinusBtn } from '../../../../assets/minus.svg';
import { ReactComponent as Counter } from '../../../../assets/counter.svg';


export default function Map() {
    const container = useRef<HTMLDivElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);
    const { guesses, highlighted, setHighlighted } = useGameContext();

    const darker = (color: string, k = 1) => {
        return d3.color(color)!.darker(k).formatHex();
    };

    useEffect(() => {
        if (svgRef.current && container.current) {
            const viewBox = { x: -210, y: -210, w: 420, h: 420 };
            const constraints = [
                [viewBox.x, viewBox.y],
                [viewBox.x + viewBox.w, viewBox.y + viewBox.h]
            ].map(arr => arr.map(v => v));

            const svg = d3.select(svgRef.current);
            const onResize = () => {
                if (svgRef.current && container.current) {
                    svg.attr('viewBox', `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`);
                    svg.attr('pointer-events', 'all');
                }
            }
            onResize();

            window.addEventListener('resize', function (event) {
                onResize();
            });
            const g = svg.append('g');

            const xScale = d3.scaleLinear()
                .domain([-1, 1])
                .range([viewBox.x, viewBox.x + viewBox.w]);
            const yScale = d3.scaleLinear()
                .domain([-1, 1])
                .range([viewBox.y, viewBox.y + viewBox.h]);

            const getWordPosition = (guess: Guess) => {
                const [x, y] = polarToCartesian(
                    guess.distance,
                    degToRad(guess.angle)
                );

                const scaleDown = Math.max(1, Math.sqrt(x ** 2 + y ** 2));
                return [
                    xScale(x / scaleDown),
                    yScale(y / scaleDown)
                ];
            };

            let currentScale = 1;

            const redraw = () => {
                const rectHeight = 30 / currentScale;
                const fontSize = 18 / currentScale;
                const rx = 15 / currentScale;
                const margin = 10 / currentScale;

                const getRectWidth = (word: string) => {
                    return 2 * margin + (BrowserText.getWidth(word, 18, 'Jost') / currentScale);
                };

                g.selectAll('*').remove();

                const circlesNum = 9;
                const circleDelta = viewBox.w / (2 * circlesNum + 2);

                g.append('circle')
                    .attr('cx', 0)
                    .attr('cy', 0)
                    .attr('r', viewBox.w)
                    .attr('fill', 'transparent');

                Array.from(Array(circlesNum).keys()).reverse().forEach(i => {
                    g.append('circle')
                        .attr('cx', 0)
                        .attr('cy', 0)
                        .attr('r', (i + 2) * circleDelta)
                        .attr('stroke', colors[i % colors.length])
                        .attr('fill', colors[i % colors.length]);
                })

                g.append('image')
                    .attr('xlink:href', 'star.svg')
                    .attr('x', -10 / currentScale)
                    .attr('y', -10 / currentScale)
                    .attr('width', 20 / currentScale)
                    .attr('height', 20 / currentScale);

                const words = g.selectAll('word')
                    .data(guesses)
                    .enter()
                    .append('g')
                    .attr('transform', d => `translate(${getWordPosition(d).join(', ')})`);

                // words.append('rect')
                //     .attr('x', d => - getRectWidth(d.word) / 2)
                //     .attr('y', d => - rectHeight / 2)
                //     .attr('width', d => getRectWidth(d.word))
                //     .attr('height', rectHeight)
                //     .attr('rx', rx)
                //     .attr('fill', 'var(--bg)')

                words.append('rect')
                    .attr('id', d => `word-rect-${d.position}`)
                    .attr('x', d => - getRectWidth(d.word) / 2)
                    .attr('y', - rectHeight / 2)
                    .attr('width', d => getRectWidth(d.word))
                    .attr('height', rectHeight)
                    .attr('rx', rx)
                    .attr('fill', d => d.color)
                    .attr('stroke', '#fff')
                    .attr('stroke-width', 1 / currentScale);

                words.append('text')
                    .classed(classes.wordText, true)
                    .attr('x', d => margin - getRectWidth(d.word) / 2)
                    .attr('y', fontSize / 4)
                    .text(d => d.word)
                    .attr('font-size', fontSize)
                    .attr('font-weight', 500)
                    .attr('fill', 'var(--fg-dark)');

                words.on('mouseover', (el, d) => {
                    setHighlighted(d);
                    g.select(`#word-rect-${d.position}`)
                        .transition()
                        .duration(200)
                        .attr('stroke', darker(d.color));
                });
                words.on('mouseout', (el, d) => {
                    setHighlighted(null);
                    g.select(`#word-rect-${d.position}`)
                        .transition()
                        .duration(200)
                        .attr('stroke', '#fff');
                });
            }
            redraw();

            const zoom = d3.zoom()
                .scaleExtent([0.8, 120])
                .translateExtent(constraints as any)
                .on('zoom', ({ transform, sourceEvent }) => {
                    g.attr('transform', transform);
                    currentScale = transform.k;

                    if (sourceEvent?.type !== 'touchmove' && transform.k) {
                        redraw();
                    }
                });

            svg.call(zoom as any)
                .on('touchend.zoom', null);

            svg.call(zoom.transform as any, d3.zoomIdentity);

            g.on('touchend mouseup', () => {
                redraw();
            });

            document.getElementById('plusBtn')?.addEventListener('click', e => {
                e.preventDefault();
                zoom.scaleBy(svg.transition().duration(350).on('end', redraw) as any, 1.4);
            });
            document.getElementById('minusBtn')?.addEventListener('click', e => {
                e.preventDefault();
                zoom.scaleBy(svg.transition().duration(350).on('end', redraw) as any, 0.6);
            });

            return () => {
                svg.selectAll('*').remove();
            }
        }
    }, [svgRef, guesses]);

    useEffect(() => {
        if (svgRef.current && highlighted) {
            const svg = d3.select(svgRef.current);
            svg.select(`#word-rect-${highlighted.position}`)
                .classed(classes.wordRect, true)
                .transition()
                .duration(200)
                .attr('stroke', darker(highlighted.color));

            return () => {
                svg.select(`#word-rect-${highlighted.position}`)
                    .transition()
                    .duration(200)
                    .attr('stroke', '#fff');
            }
        }
    }, [svgRef, highlighted, guesses]);

    return (
        <div ref={container} className={classes.container}>
            <div className={classes.svgContainer} >
                <svg ref={svgRef} className={classes.svgComponent} />
            </div>
            <div className={classes.zoomControls}>
                <PlusBtn id='plusBtn' className={classes.plusBtn} />
                <MinusBtn id='minusBtn' className={classes.plusBtn} />
            </div>
            <div className={classes.counterContainer}>
                <Counter className={classes.counter} />
                {guesses.length}
            </div>
        </div>
    );
}
