import { API_URL, headers } from "./api";

type Data = {
    onSuccess?: (gameHash: string) => void;
    onError?: () => void;
};

export const getGameHash = async ({ onSuccess, onError }: Data) => {
    const response = await fetch(`${API_URL}/get_game_hash/`, {
        method: 'GET',
        headers
    });

    if (response.ok) {
        const gameHash = await response.json();
        onSuccess?.(gameHash);
    } else {
        onError?.();
    }
};
