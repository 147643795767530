import { useGameContext } from '../../utils/GameContext';
import classes from './styles.module.scss';
import { useEffect, useState } from 'react';
import { getWord } from '../../utils/api/getWord';
import { options } from './options';
import { ReactComponent as CrossIcon } from '../../assets/back.svg';
import { ReactComponent as Stars } from '../../assets/stars.svg';
import { ReactComponent as Crown } from '../../assets/crown.svg';
import { ReactComponent as Smileyface } from '../../assets/smileyface1.svg';
import paperclip from '../../assets/paperclip6.png';


export default function FinishPopup() {
    const { guesses } = useGameContext();
    const [isShown, setIsShown] = useState(true);
    const [shareButtonText, setShareButtonText] = useState('Позови друзей');

    const share = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Угадай слово',
                text: 'Я отгадал слово в игре!',
                url: 'https://guess-word.ru/'
            });
        } else {
            navigator.clipboard.writeText('https://guess-word.ru/');
            setShareButtonText('Ссылка скопирована');
        }
    };
    // const won = guesses.find(g => !g.position);
    const won = guesses[0];

    const [word, setWord] = useState('');
    useEffect(() => {
        if (!won) {
            getWord({ onSuccess: setWord });
        }
    }, [won]);

    const optionId = won ? (guesses[0].position * 2 + won.word.length) % 10 : 0;
    const option = options[optionId];

    return isShown ? (
        <>
            <div className={classes.shadow} />
            {/* <Confetti /> */}
            <div className={classes.container}>
                <img alt='paperclip' src={paperclip} className={classes.paperclip} />
                <h2 className={classes.title}>
                    Поздравляем!
                    <Stars className={classes.stars} />
                </h2>
                <div className={classes.subtitle}>
                    <p className={classes.description}>
                        {option.line1}
                        <Crown className={classes.crown} />
                    </p>
                    {option.line2.length > 0 && (
                        <p className={classes.description}>
                            {option.line2}
                            <Smileyface className={classes.smileyface} />
                        </p>
                    )}

                    <p className={classes.description}>
                        Количество попыток: {guesses.length}
                    </p>
                </div>

                <div className={classes.joinBtns}>
                    <a
                        href='https://t.me/guess_word_ru'
                        target='_blank'
                        rel='noreferrer'
                        className={classes.joinLink}
                    >
                        <button className={classes.joinBtn}>
                            Канал
                        </button>
                    </a>

                    <a
                        href='https://t.me/+IKkUXrnedZg5NDky'
                        target='_blank'
                        rel='noreferrer'
                        className={classes.joinLink}
                    >
                        <button className={classes.joinBtn}>
                            Чат
                        </button>
                    </a>
                </div>

                <button className={classes.btn} onClick={share}>
                    {shareButtonText}
                </button>

                <a
                    href='https://docs.google.com/forms/d/e/1FAIpQLSddqKg05gjAj6gzoSF7Ya0EoxP9eUmYpB6S_kizp4w636GdJA/viewform'
                    target='_blank'
                    rel='noreferrer'
                >
                    <button className={classes.btn}>
                        Пройди опрос
                    </button>
                </a>
                <CrossIcon className={classes.cross} onClick={() => setIsShown(false)} />
            </div>
            <div className={classes.filter} />
        </>
    ) : null;
}
