import classes from './styles.module.scss';
import { useState } from 'react';
import { ReactComponent as CrossIcon } from '../../assets/back.svg';
import paperclip from '../../assets/paperclip.png';
import { ReactComponent as Scribble } from '../../assets/scribble.svg';
import { ReactComponent as Smileyface } from '../../assets/smileyface.svg';
import { ReactComponent as InfoBtn } from '../../assets/help.svg';
import ReactDOM from 'react-dom';


export default function Header() {
    const [showInfo, setShowInfo] = useState(false);

    const contacts = [
        { name: 'Тихон', position: 'Machine Learning, Idea', link: 'https://t.me/tixmav' },
        { name: 'Иван', position: 'Frontend', link: 'https://t.me/IvanLudvig' },
        { name: 'Аня', position: 'Аналитик', link: 'https://t.me/ancka019' },
        { name: 'Егор', position: 'Backend', link: 'https://t.me/egorvoron' }
    ];

    return (
        <>
            <header className={classes.header}>
                <h1 className={classes.title}>
                    Угадай слово
                </h1>
                <InfoBtn className={classes.info} onClick={() => setShowInfo(true)} />
            </header>
            {showInfo && ReactDOM.createPortal(
                <>
                    <div className={classes.shadow} />
                    <div className={classes.dialog}>
                        <CrossIcon className={classes.cross} onClick={() => setShowInfo(false)} />
                        <img alt='paperclip' src={paperclip} className={classes.paperclip} />

                        <div className={classes.rulesList}>
                            <p>
                                Загадано слово дня, есть неограниченное число попыток, чтобы его угадать.
                            </p>
                            <p style={{ position: 'relative' }}>
                                После ввода слово отобразится на мишени и в списке — чем ближе к центру и выше в списке слов, тем ты ближе к загаданному слову.
                                <Scribble className={classes.scribble} />
                                <Smileyface className={classes.smileyface} />
                            </p>
                            <p>
                                Чем меньше попыток потребовалось, тем лучше.
                            </p>
                        </div>

                        <h3 className={classes.dialogSubtitle}>Контакты</h3>

                        <div className={classes.contactsList}>
                            <div className={classes.contactsSubList}>
                                {contacts.map(contact => (
                                    <a
                                        href={contact.link}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {contact.name}
                                    </a>
                                ))}
                            </div>
                            <div className={classes.contactsSubList}>
                                {contacts.map(contact => (
                                    <div>{contact.position}</div>
                                ))}
                            </div>
                        </div>

                        <div className={classes.joinBtns}>
                            <a
                                href='https://t.me/guess_word_ru'
                                target='_blank'
                                rel='noreferrer'
                                className={classes.joinLink}
                            >
                                <button className={classes.joinBtn}>
                                    Канал
                                </button>
                            </a>

                            <a
                                href='https://t.me/+IKkUXrnedZg5NDky'
                                target='_blank'
                                rel='noreferrer'
                                className={classes.joinLink}
                            >
                                <button className={classes.joinBtn}>
                                    Чат
                                </button>
                            </a>
                        </div>

                        <a
                            href='https://docs.google.com/forms/d/e/1FAIpQLSddqKg05gjAj6gzoSF7Ya0EoxP9eUmYpB6S_kizp4w636GdJA/viewform'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <button className={classes.contactBtn}>
                                Пройти опрос
                            </button>
                        </a>
                    </div>
                </>, document.body
            )}
        </>
    );
}
