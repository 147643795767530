import { useState } from 'react';
import classes from './styles.module.scss';
import { ReactComponent as SendBtn } from '../../../assets/send.svg';


type InputFieldProps = {
    forwardRef?: React.ForwardedRef<HTMLInputElement>;
    isDisabled?: boolean;
    placeholder?: string;
    onSubmit: (inputValue: string) => void;
}

export default function InputField(props: InputFieldProps) {
    const { forwardRef, isDisabled = false, placeholder, onSubmit } = props;
    const [inputValue, setInputValue] = useState('');

    return (
        <form
            action='#'
            className={classes.form}
            onSubmit={e => {
                e.preventDefault();
                if (!isDisabled) {
                    onSubmit(inputValue);
                    setInputValue('');
                }
            }}
        >
            <input
                ref={forwardRef}
                type='text'
                className={classes.input}
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                placeholder={placeholder}
                disabled={isDisabled}
            />
            <input type='submit' style={{ visibility: 'hidden', position: 'absolute' }} />
            <button type='submit' className={classes.inputButton} disabled={isDisabled}>
                <SendBtn className={classes.inputButtonSvg} />
            </button>
        </form>
    );
}
