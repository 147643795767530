export const options = [
    { title: 'Поздравляем!', line1: 'Ты угадал слово дня!', line2: 'Возвращайся завтра!' },
    { title: 'Великолепный результат!', line1: 'Ты угадал слово дня!', line2: 'Ждем тебя завтра!' },
    { title: 'Отличная работа!', line1: 'Приходи завтра и попробуй угадать быстрее!', line2: '' },
    { title: 'Круто! Слово угадано!', line1: 'А уже завтра будет новое слово!', line2: '' },
    { title: 'Ошеломительный результат!', line1: 'Не останавливайся на достигнутом!', line2: 'Возвращайся завтра снова!' },
    { title: 'Прекрасный результат!', line1: 'Не останавливайся на достигнутом!', line2: 'Возвращайся завтра снова!' },
    { title: 'Замечательный результат!', line1: 'Ждем тебя завтра снова!', line2: '' },
    { title: 'Ура! Ты угадал слово дня!', line1: 'Ждем тебя завтра снова!', line2: '' },
    { title: 'Впечатляющий результат!', line1: 'Не останавливайся на достигнутом!', line2: 'Возвращайся завтра снова!' },
    { title: 'Результат - огонь!', line1: 'Возвращайся завтра снова и попробуй угадать еще быстрее!', line2: '' },
];
